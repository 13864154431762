<template>
  <div class="home">
    <div class="mt-3 mb-3 text-center">
        <img src="@/assets/logo.png" />
    </div>
    <div class="d-flex justify-content-center">
    <div class="bg-light pt-3 mycard shadow p-3 rounded">
        <div class="text-center">
        <p class="btn btn-sm btn-success">Certificado válido</p>
        </div>
        <h4>Certificado Id</h4>
        <p>ER3454623452345</p>
        <h4>Nombre</h4>
        <p>Alejandra Estrada Maldonado</p>
        <h4>Completado el</h4>
        <p>9 septiembre 2020</p>
        <h4>Programa</h4>
        <p>asdasdsadsadas as das d</p>
    </div>
    </div>
    <div class="text-center mt-3 text-footer">
        Consultado el 12 octubre 2020 16:00
    </div>
  </div>
</template>

<script>
export default {
  name: 'Valid'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body{
    background:#eaeaea
}
.mycard{
    min-width:350px
}
.text-footer{font-size:12px}
</style>
